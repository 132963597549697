import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config: any) => {
  if (config.url === '/partners/companies') {
    delete config.headers?.common?.['company_id'];
  }

  return config;
});

export { api };





